<template>
  <div>
    <div>
      <b-modal
        id="reason-modal"
        centered
        title="Rejection Reason"
        hide-footer
      >
        <reason-modal
          :given-reason="givenReason"
          :reason-only="reasonOnly"
          @submit-reason="handleDecline"
        />
      </b-modal>
      <div v-if="!isLoading">
        <b-card>
          <template #header>
            <h4>Statistics</h4>
          </template>
          <b-row>
            <statistic-card-horizontal
              icon="ActivityIcon"
              :statistic="statistics.totalOffers"
              statistic-title="Total Requests"
            />
            <statistic-card-horizontal
              color="success"
              icon="UserCheckIcon"
              :statistic="statistics.publishedOffers"
              statistic-title="Published Requests"
            />
            <statistic-card-horizontal
              color="success"
              icon="UserCheckIcon"
              :statistic="statistics.approvedOffers"
              statistic-title="Approved Requests"
            />
            <statistic-card-horizontal
              color="warning"
              icon="UserXIcon"
              :statistic="statistics.declinedOffers"
              statistic-title="Declined Requests"
            />
            <statistic-card-horizontal
              color="warning"
              icon="UserMinusIcon"
              :statistic="statistics.pendingOffers"
              statistic-title="Pending Requests"
            />
          </b-row></b-card>
      </div>
      <b-card header="Search">
        <b-form-row>
          <b-col
            md="6"
            class="mb-1 mb-md-0"
          >
            <b-form-group
              label="Name"
              label-for="search"
            >
              <search
                placeholder="Name"
                :search-query.sync="query.keyword"
                @refresh="$refs.table.refresh()"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          >
            <b-form-group
              label="Duration"
              label-for="date-range-filter"
            >
              <date-range-filter
                :date-range.sync="query.range"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <custom-vue-select
              id="status"
              label="Status"
              placeholder="Status"
              :value-reducer="option=>option.value"
              :options="[
                {name:'All',value:null},
                {name:'Published',value:'published'},
                {name:'Pending',value:'pending'},
                {name:'Approved',value:'approved'},
                {name:'Declined',value:'decline'},
              ]"
              :selected.sync="query.suggestion_status"
              text-field="name"
            />
          </b-col>

          <b-col
            md="6"
          >
            <event-type-select
              :event-type.sync="query.categoryId"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0 ml-auto"
          >
            <search-button @refresh="$refs.table.refresh()" />
          </b-col>
        </b-form-row>
      </b-card>
      <b-card>
        <b-table
          ref="table"
          :items="getEvents"
          :fields="fields"
          :current-page="pagination.currentPage"
          :per-page="pagination.perPage"
          responsive
          show-empty
        >
          <template #cell(eventName)="{item}">
            <b-link :to="{name:'suggested-event-view',params:{id:item.id} }">
              {{ item.eventName }}
            </b-link>
          </template>
          <template #cell(image)="{item}">
            <b-link
              :href="item.image"
              target="_blank"
            >
              View Image
            </b-link>
          </template>
          <template #cell(actions)="{item}">
            <b-dropdown
              variant="link"
              no-caret
            >
              <b-dropdown-item :to="{name:'original-suggested-event-view',params:{id:item.id} }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">View Original Request</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'suggested-event-view',params:{id:item.id} }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">View</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'suggested-events-notes',params:{id:item.id} }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Notes</span>
              </b-dropdown-item>
              <template v-if="item.suggestion_status==='decline'">
                <b-dropdown-item @click="showDeclineModal(item, true)">
                  <feather-icon icon="AlertTriangleIcon" />
                  <span class="align-middle ml-50">View Rejection Reason</span>
                </b-dropdown-item>
              </template>
              <template v-if="item.suggestion_status==='pending'">
                <b-dropdown-item
                  v-if="$can('update','global-ScrappedEventController')"
                  :to="{name:'edit-suggested-event',params:{id:item.id} }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="toggleSuggest(item, 'approved')">
                  <div>
                    <feather-icon icon="CheckIcon" />
                    <span
                      class="align-middle ml-50"
                    >Approve</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="showDeclineModal(item)">
                  <div class="text-danger">
                    <feather-icon icon="TrashIcon" />
                    <span
                      class="align-middle ml-50"
                    >Decline</span>
                  </div>
                </b-dropdown-item>
              </template>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </div>
  </div>
</template>
<script>
import Events from '@/common/compositions/QuickEvents/quickEventApi'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import EventTypeSelect from '@/common/components/QuickEvents/EventTypeSelect.vue'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ReasonModal from '@/common/components/Offers/ReasonModal.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'EventsList',
  components: {
    Pagination,
    Search,
    DateRangeFilter,
    SearchButton,
    EventTypeSelect,
    StatisticCardHorizontal,
    ReasonModal,
    CustomVueSelect,
  },
  data() {
    return {
      query: {
        range: [],
        category: {},
        suggestion_status: null,
        keyword: '',
      },
      isLoading: true,
      statistics: {},
      declineItem: null,
      toggleData: {},
      reasonOnly: null,
      givenReason: '',
    }
  },
  computed: {
    fields() {
      return [
        'id',
        {
          key: 'eventName', sortable: true, label: 'Title', thStyle: 'text-align:center;font-weight:bolder;',
        },
        { key: 'user.email', label: 'email', thStyle: 'text-align:center;font-weight:bolder;' },
        { key: 'user.phone_number', label: 'phone', thStyle: 'text-align:center;font-weight:bolder;' },
        { key: 'startDate', sortable: true, thStyle: 'text-align:center;font-weight:bolder;' },
        { key: 'endDate', sortable: true, thStyle: 'text-align:center;font-weight:bolder;' },
        { key: 'eventCategory.title', label: 'category', thStyle: 'text-align:center;font-weight:bolder;' },
        { key: 'suggestion_status', sortable: true, thStyle: 'text-align:center;font-weight:bolder;' },
        'actions',
      ]
    },
    formattedQuery() {
      return {
        search: this.query.search,
        date_from: this.query.range[0],
        date_to: this.query.range[1],
        categoryId: this.query.categoryId,
        suggestion_status: this.query.suggestion_status,
        keyword: this.query.keyword,
      }
    },
  },
  mounted() {
    this.setStats()
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    const { listSuggestedEventsRequest, toggleSuggestedEventsRequest, getEventStatistics } = Events()
    return {
      pagination, listSuggestedEventsRequest, toggleSuggestedEventsRequest, successfulOperationAlert, getEventStatistics,
    }
  },
  methods: {
    getEvents(ctx) {
      return this.listSuggestedEventsRequest({
        ...this.formattedQuery,
        page: this.pagination.currentPage,
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      })
    },
    toggleSuggest(item, newStatus) {
      this.toggleData.suggest_status = newStatus
      this.toggleSuggestedEventsRequest(item.id, this.toggleData).then(() => {
        const message = newStatus === 'approved' ? 'Event is approved successfully' : 'Event is declined successfully'
        this.successfulOperationAlert(message)
        item.suggestion_status = newStatus
        this.$refs.table.refresh()
      })
    },
    setStats() {
      this.getEventStatistics().then(res => {
        this.isLoading = false
        this.statistics = res.data.data
      })
    },
    showDeclineModal(item, reasonOnly = false) {
      this.reasonOnly = reasonOnly
      this.givenReason = item.reject_reason
      this.declineItem = item
      this.$bvModal.show('reason-modal')
    },
    handleDecline(reason) {
      this.toggleData.reject_reason = reason
      this.toggleSuggest(this.declineItem, 'decline')
      this.$bvModal.hide('reason-modal')
    },
  },
}
</script>
<style lang="">

</style>
